import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_SEGMENTS_MANAGE } from 'Consts/routes';
import { USER_SEGMENT_SOURCE_SYSTEM } from 'Consts/userSegments';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class AdminUsersSegmentsList extends Component {
    static propTypes = {
        actions: PropTypes.shape({
            listSegments: PropTypes.func.isRequired,
        }),
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        segments: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
            meta: PropTypes.object,
            error: PropTypes.bool,
        }),
    };
    static defaultProps = {};

    defaultQuery = {
        page: 1,
        search: '',
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadSegments();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadSegments();
        }
    }

    loadSegments = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.listSegments({ ...queryObject });
    }

    render() {
        const { segments, location, history } = this.props;

        return (
            <StyledComponent
                className="chat-mass-messaging-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={segments}
                    queryConfig={{}}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name}
                            additionals={[{
                                name: 'Liczba użytkowników w segmencie',
                                value: element.usersCount,
                            }]}
                            controls={[{
                                visible: element.source !== USER_SEGMENT_SOURCE_SYSTEM,
                                type: 'button',
                                label: 'Zarządzaj',
                                to: withVariables(ADMIN_USER_SEGMENTS_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        },
                    ]}
                />
            </StyledComponent>
        );
    }
}
