import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_CHAT_MASS_MESSAGING } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import ChatMassMessagingList from 'Components/admin/chatMassMessaging/ChatMassMessagingList';
import UsersSegmentsList from 'Components/admin/users/UsersSegmentsList';
export const TAB_LIST = 'list';
export const TAB_SEGMENTS = 'segments';

export default class AdminChatConversations extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-chat-mass-messaging"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING.path),
                        label: 'Masowa wysyłka wiadomości',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Masowa wysyłka wiadomości"
                        controls={[{
                            visible: !queryObject.tab || queryObject.tab === 'list',
                            key: 'createMassMessage',
                            label: 'Utwórz masową wiadomość',
                            onClick: () => history.push('/admin/chat-mass-messaging/create'),
                        }, {
                            visible: queryObject.tab === 'segments',
                            key: 'createUserSegment',
                            label: 'Utwórz segment użytkowników',
                            onClick: () => history.push('/admin/users/segments/create'),
                        }]}
                        tabs={[{
                            key: TAB_LIST,
                            label: 'Lista wysyłek',
                            children: (
                                <ChatMassMessagingList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_SEGMENTS,
                            label: 'Segmenty użytkowników',
                            children: (
                                <UsersSegmentsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>

            </StyledComponent>
        );
    }
}
