import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    MASS_MESSAGING_STATUS_QUEUED,
    MASS_MESSAGING_STATUS_IN_PROGRESS,
    MASS_MESSAGING_STATUS_ERROR,
    MASS_MESSAGING_STATUS_SUCCESS,
} from 'Consts/chat';
import { ADMIN_CHAT_MASS_MESSAGING_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class AdminChatMassMessagingList extends Component {
    static propTypes = {
        actions: PropTypes.shape({
            listMassMessages: PropTypes.func.isRequired,
        }),
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        massMessages: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
            meta: PropTypes.object,
            error: PropTypes.bool,
        }),
    };
    static defaultProps = {};

    defaultQuery = {
        page: 1,
        status: '',
        timeStatus: '',
        orderBy: 'DESC',
    };

    statuses = {
        [MASS_MESSAGING_STATUS_QUEUED]: 'W kolejce',
        [MASS_MESSAGING_STATUS_IN_PROGRESS]: 'W trakcie wysyłki',
        [MASS_MESSAGING_STATUS_ERROR]: 'Błąd',
        [MASS_MESSAGING_STATUS_SUCCESS]: 'Wysyłka zakończona sukcesem',
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadMessages();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadMessages();
        }
    }

    loadMessages = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.listMassMessages({ ...queryObject });
    }

    parseStatus = status => {
        if (!this.statuses.hasOwnProperty(status)) {
            return 'Undefined';
        }

        return this.statuses[status];
    }

    render() {
        const { location, history, massMessages } = this.props;

        return (
            <StyledComponent
                className="chat-mass-messaging-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={massMessages}
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={`Data wysyłki: ${getFormattedDate(element.toSendAt)}`}
                            subtitle={`Status: ${this.parseStatus(element.status)}`}
                            additionals={[{
                                name: 'Segment',
                                value: element.segment.name,
                            }, {
                                name: 'Kategoria',
                                value: element.chatConversationCategory,
                            }, {
                                name: 'Data utworzenia',
                                value: getFormattedDate(element.createdAt),
                            }, {
                                name: 'Użytkownik',
                                value: element.creator.email,
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_CHAT_MASS_MESSAGING_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'status',
                            label: 'Status',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'W kolejce',
                                    value: MASS_MESSAGING_STATUS_QUEUED,
                                }, {
                                    label: 'W trakcie wysyłki',
                                    value: MASS_MESSAGING_STATUS_IN_PROGRESS,
                                }, {
                                    label: 'Błędne',
                                    value: MASS_MESSAGING_STATUS_ERROR,
                                }, {
                                    label: 'Zakończone sukcesem',
                                    value: MASS_MESSAGING_STATUS_SUCCESS,
                                }],
                            },
                        }, {
                            name: 'timeStatus',
                            label: 'Status czasowy',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'Przyszłe',
                                    value: 'future',
                                }, {
                                    label: 'Wysłane',
                                    value: 'sent',
                                }],
                            },
                        }, {
                            name: 'orderBy',
                            label: 'Sortuj',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Według daty wysyłki rosnąco',
                                    value: 'ASC',
                                }, {
                                    label: 'Według daty wysyłki malejąco',
                                    value: 'DESC',
                                }],
                            },
                        },
                    ]}
                />
            </StyledComponent>
        );
    }
}
