import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_CHAT_MASS_MESSAGING, ADMIN_CHAT_MASS_MESSAGING_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import ChatMassMessagingForm from 'Components/admin/chatMassMessaging/ChatMassMessagingForm';
import { TAB_LIST } from 'Components/pages/admin/ChatMassMessaging/component';

export default class AdminChatMassMessageCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-user-segments-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING.path),
                        label: 'Masowa wysyłka wiadomości',
                    }, {
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING.path, {}, { tab: TAB_LIST }),
                        label: 'Lista wysyłek',
                    }, {
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING_CREATE.path),
                        label: 'Nowa wysyłka masowa',
                    }]}
                >
                    <ViewHeader
                        title="Nowa wysyłka masowa"
                    />
                    <ChatMassMessagingForm
                        history={history}
                        location={location}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}