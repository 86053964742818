import { connect } from 'react-redux';
import Component from './component';

import {
    listSegments,
} from 'Redux/modules/admin/userSegments/actions';

export default connect(
    state => ({
        segments: state.adminUserSegments.list,
    }),
    dispatch => ({
        actions: {
            listSegments: dispatch(listSegments),
        },
    })
)(Component);
