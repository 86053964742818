import { connect } from 'react-redux';
import Component from './component';

import {
    listMassMessages,
} from 'Redux/modules/admin/chat/actions';

export default connect(
    state => ({
        massMessages: state.adminChat.massMessagingList,
    }),
    dispatch => ({
        actions: {
            listMassMessages: dispatch(listMassMessages),
        },
    })
)(Component);
